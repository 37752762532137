import * as React from "react";

import {
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { useDomain } from "../../utils";

const useHotelInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  const { dir_con, about, hotel_services_image, rooms_image , directory_description, dining_image, artNdesign, neighbourhood, events, safetyNsecurity} =
    propertyMeta?.getMeta || {};

  return {
    loadingMeta,
    about,
    artNdesign,
    neighbourhood,
    events,
    safetyNsecurity,
    dir_con,
    directory_description,
    phoneNumber: dir_con?.phone,
    directionImage: dir_con?.img,
    propertyDetails,
    hotelRoomsImage: rooms_image,
    hotelDiningImage: dining_image,
    hotelServicesImage: hotel_services_image,
    loading: loadingMeta || loadingProperty,
    isActiveAbout:propertyMeta?.getMeta?.is_Activated?.about,
    isActiveDining:propertyMeta?.getMeta?.is_Activated?.dining,
    isActiveHotel_services:propertyMeta?.getMeta?.is_Activated?.hotel_services,
    isActiveHotel_rooms:propertyMeta?.getMeta?.is_Activated?.hotel_rooms,
    isActiveDirectory:propertyMeta?.getMeta?.is_Activated?.directory,
    isActiveArtNdesign :propertyMeta?.getMeta?.is_Activated?.artNdesign ,
    isActiveNeighbourhood : propertyMeta?.getMeta?.is_Activated?.neighbourhood ,
    isActiveEvents:propertyMeta?.getMeta?.is_Activated?.events,
    isActiveSafetyNsecurity: propertyMeta?.getMeta?.is_Activated?.safetyNsecurity,
    artNdesignImg :propertyMeta?.getMeta?.artNdesign?.img,
    neighbourhoodImg : propertyMeta?.getMeta?.neighbourhood?.img ,
    eventsImg:propertyMeta?.getMeta?.events?.img,
    safetyNsecurityImg: propertyMeta?.getMeta?.safetyNsecurity?.img,
    order_service_id:propertyMeta?.getMeta?.order_service_id,
    has_order_service_id:propertyMeta?.getMeta?.has_order_service_id,
    hide_all_menu:propertyDetails?.getPropertyByDomain?.hide_all_menu,
    spa_id:propertyDetails?.getPropertyByDomain?.spa_id,
    send_to_simphony: propertyDetails?.getPropertyByDomain?.send_to_simphony,
    chat_text: propertyDetails?.getPropertyByDomain?.chat_text,
    feedback_text: propertyDetails?.getPropertyByDomain?.feedback_text
  };
};

export default useHotelInfo;

import create from "zustand";
// import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type User = {
  id: string;
  name: string;
}
let store: any = (set: any) => ({
  user: null,
  setEMenuGuest: (user: User) => {
    set((state: any) => {
      state.user= user;
    });
  },
});

// store = devtools(store);
store = immer(store);

const useEMenuAuth = create(store);

export { useEMenuAuth };

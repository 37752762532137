import * as React from 'react';
import { format } from 'date-fns';
import { CardPaper } from '../Dashboard/components';
import { HeaderLayout, Layout } from '../E-Menu';
import { Cart, Order, Status } from '../../generated/graphql';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { useLocation, useOutletContext } from 'react-router-dom';

interface LocationState {
  orders: Order[];
}
function OrderHistory() {
  const location = useLocation();
  const orders = (location.state as LocationState)?.orders;
  const { property } = useOutletContext<any>();
  const currency = property?.currency;
  const { orderList, total } = React.useMemo(() => {
    if (!orders) {
      return { orderList: [], total: 0 };
    }

    const orderList = orders
      ?.slice()
      .sort(
        (a: any, b: any) =>
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
      );

    const total = orderList.reduce((acc: any, curr: any) => {
      return curr.status !== Status.Cancelled ? acc + curr.total : acc;
    }, 0);

    return { orderList, total };
  }, [orders]);

  return (
    <Layout index={2}>
      <HeaderLayout title="Order History" allowBack />
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: 'auto',
          textAlign: 'left',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 600 }}>
            Total Spent:
          </Typography>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 600 }}>
            {currency} {total.toFixed(2)}
          </Typography>
        </Stack>
        {orderList?.map((order: any) => (
          <CardPaper key={order?.id} sx={{ p: 2, mt: 3 }}>
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {format(new Date(order?.created_at), 'dd-MM-yyyy hh:mm a')}
              </Typography>
              {order?.status === Status.Pending && (
                <Chip
                  label="Pending"
                  size="small"
                  color="warning"
                  variant="filled"
                />
              )}
              {order?.status === Status.InProgress && (
                <Chip
                  label="InProgress"
                  size="small"
                  // color="primary"
                  variant="filled"
                  sx={{ bgcolor: 'primary.main', color: 'white' }}
                />
              )}
              {order?.status === Status.Delivered && (
                <Chip
                  label="Completed"
                  size="small"
                  color="success"
                  variant="filled"
                />
              )}
              {order?.status === Status.Cancelled && (
                <Chip
                  label="Cancelled"
                  size="small"
                  color="error"
                  variant="filled"
                />
              )}
            </Box>
            {order?.cart_items?.map((cartItem: Cart) => (
              <Box
                sx={{
                  mb: 1,
                  mx: 1,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                key={cartItem?.dineInItem_id}
              >
                <Typography variant="body2" sx={{ flex: 3 }}>
                  {cartItem?.dineInItem_name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, textAlign: 'center' }}
                >
                  x {cartItem?.quantity}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, textAlign: 'right' }}
                >
                  {currency} {cartItem?.dineInItem_price * cartItem?.quantity}
                </Typography>
              </Box>
            ))}

            <Divider />
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="body2">Total</Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {currency} {order?.total}
              </Typography>
            </Box>
          </CardPaper>
        ))}
      </Box>
    </Layout>
  );
}

export default OrderHistory;

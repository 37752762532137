import useVerify from './otp-hook';
import { Box, Button, TextField, Typography } from '@mui/material';

import { imageFallback } from '../../utils';
import { FullScreenLoader } from '../../components';

const VerifyUser = () => {
  const {
    imgUrl,
    loading,
    errorsOtp,
    registerOtp,
    onSubmitDetails,
    handleGetOtp,
    otpSent, // This should indicate whether OTP was sent successfully
    errorsEnterOtp,
    registerEnterOtp,
    onSubmitEnterOtp,
    onSubmitOtp,
    Label,
    Placeholder
  } = useVerify();

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      sx={{
        p: 2,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: {
          xs: '90%', // 0px to 600px
          sm: '80%', // 600px to 900px
          md: '60%', // 900px to 1200px
          lg: '60%' // 1200px and up
        },
        justifyContent: 'center',
        margin: 'auto', // Auto margins to center horizontally and vertically
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)' // Translate to adjust centering
      }}
      gap={2}
      onSubmit={
        otpSent ? onSubmitEnterOtp(onSubmitOtp) : handleGetOtp(onSubmitDetails)
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 3,
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          maxHeight="100px"
          sx={{
            objectFit: 'contain',
            maxWidth: '80%'
          }}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      {otpSent ? (
        <>
          <Typography
            variant="h6"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            Enter OTP
          </Typography>
          <TextField
            required
            sx={{ mt: 1, input: { color: 'text.tile' } }}
            variant="outlined"
            label="OTP"
            {...registerEnterOtp('otp', {
              required: 'Please enter the OTP!'
            })}
            error={Boolean(errorsEnterOtp.otp)}
            helperText={errorsEnterOtp.otp?.message}
          />
          <Button
            type="submit"
            sx={{ mt: 3 }}
            variant="outlined"
            color="primary"
          >
            Submit OTP
          </Button>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            Please enter your details
          </Typography>
          <TextField
            required
            sx={{ mt: 1, input: { color: 'text.tile' } }}
            variant="outlined"
            label="Name"
            {...registerOtp('name', {
              maxLength: 30,
              required: 'Please enter your name'
            })}
            error={Boolean(errorsOtp.id)}
            helperText={errorsOtp.name?.message}
          />
          <TextField
            required
            sx={{ mt: 1, input: { color: 'text.tile' } }}
            variant="outlined"
            label={Label}
            {...registerOtp('id', {
              maxLength: 30,
              required: Placeholder
            })}
            error={Boolean(errorsOtp.id)}
            helperText={errorsOtp.id?.message}
          />
          <Button
            type="submit"
            sx={{ mt: 3 }}
            variant="outlined"
            color="primary"
          >
            Verify
          </Button>
        </>
      )}

      {loading && <FullScreenLoader />}
    </Box>
  );
};

export default VerifyUser;

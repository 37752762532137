import {
  Box,
  Menu,
  Button,
  styled,
  MenuItem,
  Typography,
  // IconButton,
  Paper as MiUiPaper,
  SxProps,
  Theme,
  InputBase,
  IconButton,
  Badge
} from '@mui/material';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import MenuItemCard from './FoodItem';
// import { startCase, camelCase } from 'lodash';
import useEMenu from './e-menu-hook';
import { FullScreenLoader } from '../../components';
import useInfo from '../Dashboard/components/InfoCard';
// import useHotelService from '../HotelSer/hotel-ser-hooks';
// import { groupBy } from 'lodash';
import { MENU_ITEM_FONT } from '../../constants';
// import TaskHistoryProvider from '../../context/TaskHistoryContext';
// import NavBar from '../Dashboard/components/NavBar';
import { ItemDrawer } from './ItemDrawer';
import { useCart } from '../../store';
import { NavBasketIcon } from '../../components/Icons/NavBasketIcon';
import { EMenu, Order } from '../../generated/graphql';
const ALL_MENU = 'ALL_MENU';

interface HeaderLayoutProps {
  title: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  orders?: Order[];
  EMenu?: EMenu;
  allowBack?: boolean;
}

export const HeaderLayout = ({
  sx,
  title,
  children,
  orders,
  // EMenu,
  allowBack
}: HeaderLayoutProps) => {
  // const { logoTileBg, logoTileOpacity } = useInfo();
  const { EMenu, property } = useOutletContext<any>();
  const logoTileBg = property?.logo_tile_bg;
  const logoTileOpacity = property?.logo_tile_transperancy;

  const navigate = useNavigate();
  const backgroundStyles = logoTileBg
    ? {
        background: `linear-gradient(rgba(43, 43, 43, ${
          1.0 - (logoTileOpacity || 0.3)
        }), rgba(43, 43, 43, ${
          1.0 - (logoTileOpacity || 0.7)
        })), url(${logoTileBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0px 0px 30px 30px'
      }
    : {};
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media(max-width:280px)': {
          flexDirection: 'column'
        },
        boxShadow:
          '0px 8px 10px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.06)',
        ...backgroundStyles,
        ...(sx ? sx : {})
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {allowBack && (
          <IconButton disableRipple onClick={() => navigate(-1)}>
            <ArrowBackIcon sx={{ color: 'white' }} />
          </IconButton>
        )}
        <Typography
          variant="h6"
          sx={{ cursor: 'pointer', ...MENU_ITEM_FONT }}
          // onClick={() => navigate(-1)}
        >
          {title}
        </Typography>
      </Box>
      <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
        <IconButton
          sx={{ cursor: 'pointer', marginRight: 3, ...MENU_ITEM_FONT }}
          onClick={() =>
            navigate(`/e-menu/${EMenu?.id}/order-history`, {
              state: { orders }
            })
          }
        >
          <Badge color="secondary" badgeContent={orders?.length}>
            <NavBasketIcon />
          </Badge>
        </IconButton>
        {children}
      </Box>
    </Box>
  );
};
interface HeaderProps {
  categories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
  title: string;
  orders: any;
  EMenu: EMenu;
}

const Header = (props: HeaderProps) => {
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, currentCategory, onClick, title, orders, EMenu } = props;
  return (
    <HeaderLayout title={title} orders={orders} EMenu={EMenu}>
      {categories.length > 1 ? (
        <>
          <Paper sx={{ backgroundColor: 'white', mr: -1.5 }}>
            <Button
              onClick={handleClick}
              sx={{ color: 'text.tile' }}
              endIcon={
                openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {/* {startCase(camelCase(currentCategory))} */}
              {currentCategory === ALL_MENU ? 'All Menu' : currentCategory}
            </Button>
          </Paper>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              key={ALL_MENU}
              onClick={() => {
                onClick && onClick(ALL_MENU);
                handleClose();
              }}
              sx={{ color: 'text.tile', backgroundColor: 'white' }}
            >
              All Menu
            </MenuItem>
            {categories.map(category => (
              <MenuItem
                key={category}
                onClick={() => {
                  onClick && onClick(category);
                  handleClose();
                }}
                sx={{ color: 'text.tile', backgroundColor: 'white' }}
              >
                {/* {startCase(camelCase(category))} */}
                {category}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        ''
      )}
    </HeaderLayout>
  );
};

// const SubCategory = (props: SubCategoryProps) => {
//   const { hide_all_menu } = useHotelInfo();
//   // Hooks
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

//   // Vars
//   const openMenu = Boolean(anchorEl);

//   // Handlers
//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const { subCategories, currentCategory, onClick } = props;

//   return (
//     <>
//       <Paper sx={{ backgroundColor: 'white' }}>
//         <Button
//           onClick={handleClick}
//           sx={{ color: 'text.tile' }}
//           endIcon={
//             openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
//           }
//         >
//           {startCase(camelCase(currentCategory))}
//         </Button>
//       </Paper>

//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={openMenu}
//         onClose={handleClose}
//         MenuListProps={{
//           'aria-labelledby': 'basic-button'
//         }}
//       >
//         {!hide_all_menu && (
//           <MenuItem
//             key={ALL_MENU}
//             onClick={() => {
//               onClick && onClick(ALL_MENU);
//               handleClose();
//             }}
//             sx={{ color: 'text.tile', backgroundColor: 'white' }}
//           >
//             {startCase(camelCase(ALL_MENU))}
//           </MenuItem>
//         )}
//         {subCategories?.map(category => (
//           <MenuItem
//             key={category}
//             onClick={() => {
//               onClick && onClick(category);
//               handleClose();
//             }}
//             sx={{ color: 'text.tile', backgroundColor: 'white' }}
//           >
//             {startCase(camelCase(category))}
//           </MenuItem>
//         ))}
//         {/* <MenuItem onClick={handleClose}>My account</MenuItem>
//           <MenuItem onClick={handleClose}>Logout</MenuItem> */}
//       </Menu>
//     </>
//   );
// };

const Footer = () => {
  const navigate = useNavigate();
  const { EMenuID } = useParams();
  const cart = useCart((state: any) => state.cart);
  const totalItems = cart.reduce(
    (prev: number, current: any) => prev + current.count,
    0
  );

  if (totalItems === 0) return null;

  return (
    <Box sx={{ p: 1 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(`/e-menu/${EMenuID}/cart`)}
      >
        View Cart ({totalItems} Items)
      </Button>
    </Box>
  );
};

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  border: '1px solid #F0F0F0',
  boxShadow: 'none',
  borderRadius: theme.spacing(1.875)
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  paddingRight: '4px !important',
  paddingLeft: '4px !important',
  borderRadius: '5px',
  fontSize: '13px'
}));

interface LayoutProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  index?: number;
}

// Using position fixed because of chrome mobile's moving url bar's behavior
// https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile

export const Layout = ({ children, sx, index }: LayoutProps) => {
  const { bgImage, bgOpacity } = useInfo();
  const backgroundStyles = bgImage
    ? {
        background: `linear-gradient(rgba(255,255,255,${
          1 - (bgOpacity || 0)
        }), rgba(255,255,255,${1 - (bgOpacity || 0)})),url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    : { backgroundColor: 'background.light' };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          top: 0,
          bottom: 0,
          paddingBottom: index ? 9 : 0,
          width: '100%',
          minHeight: 'unset',
          flexDirection: 'column',
          ...backgroundStyles,
          ...(sx ? sx : {})
        }}
      >
        {children}
      </Box>
      {/* {index && (
        <TaskHistoryProvider>
          <NavBar index={index - 1}></NavBar>
        </TaskHistoryProvider>
      )} */}
    </>
  );
};

// TODO: Extract All component above into a separate folder
const E_Menu = () => {
  const {
    loading,
    Emenu,
    categories,
    groupedFilteredItems,
    currentCategory,
    setCurrentCategory,
    setSearch,
    openDialog,
    handleOpen,
    handleClose,
    ALLERGEN_NAMES,
    dialogData,
    currency,
    isAuthenticated,
    orders
  } = useEMenu();
  // const { background_color } = useInfo();
  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        categories={categories}
        onClick={setCurrentCategory}
        currentCategory={currentCategory}
        title={Emenu?.name || 'E-Menu'}
        orders={orders?.getEMenuGuestOrders}
        EMenu={Emenu}
      />
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: '0px 10px ',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {/* Header */}

        <InputBase
          placeholder="Search item..."
          onChange={(e: any) => setSearch(e.target.value)}
          sx={{
            p: 1.5,
            width: '100%',
            borderRadius: 1.25,
            backgroundColor: '#fff',
            color: 'text.tile',
            mb: 1,
            mt: 1
          }}
        />

        {Object.entries(groupedFilteredItems)
          ?.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
          ?.map(([key, value], i) => {
            return (
              <React.Fragment key={key}>
                {value?.length > 0 && (
                  <Box
                    position={'sticky'}
                    top={0}
                    zIndex={100 + i}
                    sx={{
                      backgroundColor: 'primary.light',
                      p: 1,
                      borderRadius: 1.25
                    }}
                  >
                    <Typography sx={{ mb: 1, color: 'text.tile' }} variant="h6">
                      {key === ALL_MENU ? 'All Menu' : key}
                    </Typography>
                  </Box>
                )}
                {value?.map(menu => {
                  return (
                    <MenuItemCard
                      item={menu}
                      key={menu?.id}
                      handleOpen={handleOpen}
                      isAuthenticated={isAuthenticated}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
      </Box>
      <ItemDrawer
        open={openDialog}
        ALLERGEN_NAMES={ALLERGEN_NAMES}
        toggleDrawer={handleClose}
        item={dialogData}
        currency={currency}
      />
      <Footer />
    </Layout>
  );
};

export default E_Menu;

import { Box, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  chatText: string | undefined | null;
  feedbackText: string | undefined | null;
  serviceText: string | undefined | null;
}
const ButtonSection = ({ chatText, feedbackText, serviceText }: Props) => {
  const navigation = useNavigate();
  return (
    <Box
      sx={{
        px: 1.5,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2
      }}
    >
      <Chip
        variant="filled"
        // color="primary"
        sx={{
          flex: 1,
          bgcolor: 'primary.main',
          color: 'white',
          py: 1
          // [`& .${chipClasses.icon}`]: {
          //   color: 'white'
          // }
        }}
        label={chatText || "Let's Chat"}
        // icon={<WifiCalling3Icon fontSize="small" />}
        onClick={e => {
          e.preventDefault();
          navigation('/chat', { state: chatText });
        }}
      />
      <Chip
        variant="filled"
        // color="primary"
        sx={{
          flex: 1,
          bgcolor: 'primary.main',
          color: 'white',
          py: 1
          // [`& .${chipClasses.icon}`]: {
          //   color: 'white'
          // }
        }}
        label={serviceText || 'Request a Service'}
        // icon={<WifiCalling3Icon fontSize="small" />}
        onClick={e => {
          e.preventDefault();
          navigation('services?service=ALLSERVICE');
        }}
      />
      <Chip
        variant="filled"
        // color="primary"
        sx={{
          flex: 1,
          bgcolor: 'primary.main',
          color: 'white',
          py: 1

          // [`& .${chipClasses.icon}`]: {
          //   color: 'white'
          // }
        }}
        label={feedbackText || 'Feedback'}
        // icon={<WifiCalling3Icon fontSize="small" />}
        onClick={e => {
          e.preventDefault();
          navigation('./feedback', { state: feedbackText });
        }}
      />
    </Box>
  );
};

export default ButtonSection;

import { useForm } from "react-hook-form";
// import { useLazyQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Cache } from 'aws-amplify';

import {
    AuthMethod,
    useGetOtpLazyQuery,
  usePropertyDetailsQuery,
  useVerifyOtpLazyQuery,
} from "../../generated/graphql";
import { useEMenuAuth } from "../../store";
// import meQuery from "../../utils/meQuery";
import { useDomain, useSweetAlert } from "../../utils";
import React from "react";

interface GetOtpForm {
  id: string;
  name: string;
}

interface VerifyOtpForm {
    otp: number;
  }

  interface LocationState {
    auth: AuthMethod;
  }

const useVerify = () => {
  const sweetAlert = useSweetAlert();
  const location = useLocation();
  const { EMenuID } = useParams();
  const authMethod = (location.state as LocationState)?.auth;
  const [otpSent, setOtpSent] = React.useState(false);
  const [user, setUser] = React.useState<GetOtpForm | null>(null);
  const [getOtp, { loading: loadingVerification }] = useGetOtpLazyQuery();
  const [verifyOtp, { loading: loadingVerifyOtp }] = useVerifyOtpLazyQuery();
  const domainId = useDomain();
  const { data: propertyData, loading: proprtyLoading } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });
  const property = propertyData?.getPropertyByDomain;
  const setEMenuGuest = useEMenuAuth((state: any) => state.setEMenuGuest);
  const navigate = useNavigate();
  const {
    register: registerOtp,
    handleSubmit: handleGetOtp,
    reset: resetOtp,
    formState: { errors: errorsOtp},
    getValues: getOtpValue
  } = useForm<GetOtpForm>();

  const {
    register: registerEnterOtp,
    handleSubmit: onSubmitEnterOtp,
    formState: { errors: errorsEnterOtp },
  } = useForm<VerifyOtpForm>();

  const onSubmitDetails = async (formData: GetOtpForm, e: any) => {
    e.preventDefault();
    const { id} = formData;
    try {
      const response = await getOtp({
        variables: {
          propertyID: property?.id || "",
          id: id,
          method: authMethod,
        },
      });

      if (response.data?.getOtp?.code === "200") {
        setOtpSent(true);
        await sweetAlert
          .fire({
            text: response.data?.getOtp?.message,
            icon: "success",
            timer: 2000,
          })
          .then(() => {
            // const encryptedRoom = response.data?.verifyGuest?.room;
            // setGuest({ ...response.data?.verifyGuest, room_id: encryptedRoom, room:roomNo  });
            // Cache.setItem('user', { ...response.data?.verifyGuest, room_id: encryptedRoom, room:roomNo  });
            setUser({id:getOtpValue("id"), name:getOtpValue("name")});
            resetOtp();
            // navigate(`/${encryptedRoom}`);
          });
      } else {
        await sweetAlert.fire({
          text: response.data?.getOtp?.message,
          icon: "error",
        });
      }
    } catch (error) {}
  };

  const onSubmitOtp = async (formData: VerifyOtpForm, e: any) => {
    e.preventDefault();
    const { otp} = formData;
    try {
      const response = await verifyOtp({
        variables: {
          propertyID: property?.id || "",
         otp: otp
        },
      });

      if (response.data?.verifyOtp?.code === 200) {
        await sweetAlert
          .fire({
            text: response.data?.verifyOtp?.message,
            icon: "success",
            timer: 2000,
          })
          .then(() => {
            setEMenuGuest(user);
            Cache.setItem('userDetails', user);
            resetOtp();
            navigate(`/e-menu/${EMenuID}`);
          });
      } else {
        await sweetAlert.fire({
          text: response.data?.verifyOtp?.message,
          icon: "error",
        });
      }
    } catch (error) {}
  };

  let Label = "";
  switch (authMethod) {
    case  AuthMethod.Email: {
      Label = "Email Address";
      break;
    }
    case  AuthMethod.Phone: {
      Label = "Phone Number";
      break;      
    }
    case  AuthMethod.Room: {
      Label = "Room Number";
      break; 
    }
    case  AuthMethod.Combo: {
      Label = "Email Address or Phone Number";
      break;
    }
    default: break;
  }
  const Placeholder = `Please enter your ${Label}`;
  const imgUrl = property?.img;
  

  return {
    imgUrl,
    registerOtp,
    errorsOtp,
    onSubmitOtp,
    onSubmitDetails,
    handleGetOtp,
    registerEnterOtp,
    onSubmitEnterOtp,
    errorsEnterOtp,
    otpSent,
    loading: proprtyLoading|| loadingVerification  || loadingVerifyOtp,
    Label,
    Placeholder
  };
};

export default useVerify;
